import React, { useState } from "react";
import './modal.css'; // If you still need custom styles
import Modal from './modal';
import { ToastContainer } from 'react-toastify';
import iconfilter from "../../assets/icons/iconfilter.png";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { BASE_URL, successAlert, encryptData, S3_BASE_URL, GOOGLE_RECAPTCHA_SITE_KEY, failureAlert, IsAdmin, axiosApiInstance, videoUrl } from "../../helpers/helper";
const BookEventFilterModal = ({ modalid, toggle, setOpenBookEventFilterModal, bookEventCategories, bookEventplatforms, onCategoriesChange }) => {

    const [loaded, setLoaded] = useState(true);
    const [checkedItems, setCheckedItems] = useState({});
    const [platformItems, setplatformcheckedItems] = useState({});
    const [participants, setParticipants] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [errorParticipants, setErrorParticipants] = useState('');
    const [errorMinPrice, setErrorMinPrice] = useState('');
    const [errorMaxPrice, setErrorMaxPrice] = useState('');
    const handleCheckboxChange = (id) => {
        setCheckedItems((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const minutes = [10, 15, 30, 45, 60, 75, 90, 120, 180];
    const [minutesChecked, setMinutesChecked] = useState({});
    const handleMinutesChange = (minute) => {
        setMinutesChecked((prev) => ({
            ...prev,
            [minute]: !prev[minute],
        }));
    };
    const handleplatformChange = (id) => {
        setplatformcheckedItems((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };
    const handleTextInputChange = (e, setInput) => {
        const value = e.target.value;
        // Allow only numbers
        if (/^\d*$/.test(value) || value === '') {
            setInput(value);
            // Clear error message when input is valid
            if (setInput === setParticipants) setErrorParticipants('');
            if (setInput === setMinPrice) setErrorMinPrice('');
            if (setInput === setMaxPrice) setErrorMaxPrice('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        // Validate participants
        if (!/^\d+$/.test(participants)) {
            setErrorParticipants('Please fill out this field');
            isValid = false;
        }

        // Validate min price
        // if (!/^\d+$/.test(minPrice)) {
        //     setErrorMinPrice('Please enter a valid number for Min Price.');
        //     isValid = false;
        // }

        // // Validate max price
        // if (!/^\d+$/.test(maxPrice)) {
        //     setErrorMaxPrice('Please enter a valid number for Max Price.');
        //     isValid = false;
        // }

        // Check if max price is greater than min price
        if (parseInt(maxPrice) <= parseInt(minPrice) && maxPrice !== '') {
            setErrorMaxPrice('Max Price should be greater than Min Price');
            isValid = false;
        }

        if (isValid) {

            const selectedIds = Object.keys(checkedItems).filter(id => checkedItems[id]);
            const selectedPlatforms = Object.keys(platformItems).filter(id => platformItems[id]);
            const selectedMinutes = Object.keys(minutesChecked).filter(min => minutesChecked[min]);

            setLoaded(false);
            let obj = { categoryIds: selectedIds, minCost: minPrice, maxCost: maxPrice, platforms: selectedPlatforms, minutes: selectedMinutes.map(Number), guestNumber: participants };

            const result = await axiosApiInstance.post(BASE_URL + "/api/bookEvent/filterGames", obj)
                .then((response) => {

                    onCategoriesChange(response.data.data);
                    setOpenBookEventFilterModal(false);
                    setParticipants('');
                    setMinPrice('');
                    setMaxPrice('');
                    setLoaded(true);
                })
                .catch((error) => {
                    failureAlert("Failed to get games");
                    setLoaded(true);
                });
        }
    };


    return (
        <>

            <ToastContainer position='bottom-center' />
            <LoadingComponent loaded={loaded} />
            <Modal modalid={modalid} toggle={toggle}>
                <div className="modal-body w-full max-w-[700px] bg-white  overflow-hidden"> {/* Added padding and overflow-hidden */}
                    <ToastContainer position='bottom-center' />
                    <form className="mb-2 md:mb-4" onSubmit={handleSubmit}>
                        <div className="w-full max-h-[88vh] overflow-y-auto"> {/* Set max-height and overflow for scrolling */}
                            <div className="flex justify-between items-center mb-4" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                <div className="flex items-center justify-start flex-grow ml-8 md:justify-center md:ml-0">
                                    <img
                                        src={iconfilter} // Replace with your image source
                                        alt="Filter Icon"
                                        className="mr-2" // Add margin to the right of the icon
                                    />
                                    <h2 className="text-xl font-bold">Filters</h2>
                                </div>

                                <button
                                    style={{ fontSize: '40px' }}
                                    className="text-black-500 hover:text-gray-800 mr-6"
                                    onClick={() => setOpenBookEventFilterModal(false)}
                                >
                                    &times; {/* Close icon */}
                                </button>
                            </div>

                            <div className="p-6">

                                <h4 class="text-lg font-bold  mb-4">Group Size & Budget</h4>


                               
                                <div class="mb-4">
                                    <label for="first_name" class="block mb-2 text-md font-medium text-gray-900  font-bold">Approximately How Many Guests</label>
                                    <input type="text" placeholder="Enter Participants"
                                        value={participants}
                                        onChange={(e) => handleTextInputChange(e, setParticipants)} className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full md:w-1/2 p-2.5 " />
                                    {errorParticipants && <div style={{ color: 'red', fontSize: '14px' }}>{errorParticipants}</div>}
                                </div>

                                <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mb-4 border-b pb-8">
                                    <div class="w-full md:w-1/2">
                                        <label for="second_name" class="block mb-2 text-md font-medium text-gray-900  font-bold">Min Price</label>
                                        <input type="text" placeholder="₹ 100"
                                            value={minPrice}
                                            onChange={(e) => handleTextInputChange(e, setMinPrice)} className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " />
                                    </div>
                                    <div class="w-full md:w-1/2">
                                        <label for="third_name" class="block mb-2 text-md font-medium text-gray-900  font-bold">Max Price</label>
                                        <input type="text" placeholder="₹ 100"
                                            value={maxPrice}
                                            onChange={(e) => handleTextInputChange(e, setMaxPrice)} className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " />
                                        {errorMaxPrice && <div style={{ color: 'red', fontSize: '14px' }}>{errorMaxPrice}</div>}
                                    </div>
                                </div>

                                <div className="mb-2 md:mb-4">
                                    <h6 class="text-md font-bold  mb-4">Categories</h6>

                                    <div className=" pb-0">
                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                            {bookEventCategories.map(option => (
                                                <div class="flex items-center">
                                                    <input id="link-checkbox" type="checkbox" checked={!!checkedItems[option.id]}
                                                        onChange={() => handleCheckboxChange(option.id)} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 " />
                                                    <label for="link-checkbox" class="ml-2 ms-2 text-sm font-medium text-gray-900 ">{option.title}</label>
                                                </div>

                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <hr class="h-px my-6 bg-gray-200 border-0 dark:bg-gray-700" />
                                <div className="mb-2 md:mb-4"> {/* Reduced margin on mobile */}
                                    <h6 class="text-md font-bold  mb-4">How Long Is Your Event ?</h6>

                                    <div className="border-b pb-8">
                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                            {minutes.map(min => (
                                                <div class="flex items-center">
                                                    <input id="link-checkbox" type="checkbox" checked={!!minutesChecked[min]}
                                                        onChange={() => handleMinutesChange(min)} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 " />
                                                    <label for="link-checkbox" class="ml-2 ms-2 text-sm font-medium text-gray-900 ">{min} minutes</label>
                                                </div>

                                            ))}
                                        </div>
                                    </div>
                                </div>



                                <div className="mb-2 md:mb-4"> {/* Reduced margin on mobile */}
                                    <h6 class="text-md font-bold  mb-4">Supported Video Platforms</h6>

                                    <div className="border-b pb-8">
                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4"> {/* Grid layout on desktop */}
                                            {bookEventplatforms.map(platform => (

                                                <div class="flex items-center">
                                                    <input id="link-checkbox" type="checkbox" checked={!!platformItems[platform.id]} onChange={() => handleplatformChange(platform.id)} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 " />
                                                    <label for="link-checkbox" className="ml-2 ms-2 text-sm font-medium text-gray-900 ">{platform.name}</label>
                                                </div>


                                            ))}

                                        </div>
                                    </div>
                                </div>


                                <div className="flex justify-center mt-4">
                                    <button type="submit" class="btn btn-primary text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 ">Submit</button>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </Modal>
        </>
    );
};

export default BookEventFilterModal;